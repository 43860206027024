/**
  * Variables inside Applications From Colors, Fonts, etc
 */
$primaryColor: rgb(41, 98, 255);
$blackColor: #1f2326;
$whiteColor: #ffffff;
$greyColor: rgb(238, 238, 238);

$lightSecondColor: #9ca3ad;
$lightTitleColor: black;
$lightBackgroundColor: #f8f8f8;

$descriptionColor: #9CA3AD;
$darkSecondColor: #9ca3ad;
$darkTitleColor: white;
$darkBackgroundColor: #edf1f7;
$footerBackground: #edf1f7;

$phone: "phone";
$tab-port: "tab-portraite";
$tab-land: "tab-landscape";
$big: "bigDesktop";
