@import "../../../styles/base";

.ProjectsPageComponent {
  min-height: 100vh;

  img {
    object-fit: contain;
  }
}

.ImageContainer {
  display: inline-block;
  overflow: hidden;
  border: 0 solid red;
  border-radius: 5px;
}

.ProjectImageIcon {
  transition: 1s;
  overflow: hidden;
  transition: .4s;
  vertical-align: middle;
  border-style: none;
  display: inline-block;
  overflow: hidden;
}

.ProjectViewContainerItem {
  overflow: hidden;
}

.ProjectImageIcon:hover {
  transform: scale(1.1);
}

.ItemFilter {
  cursor: pointer;
  transition: 1s;
  font-size: small;
  text-align: center;
}

.ItemFilter:hover {
  color: $primaryColor;
}

.ProjectsDescription {
  color: $descriptionColor;
  margin-bottom: 1em;
  font-style: normal;
}

.ProjectsContainer {
  padding: 2em;
  overflow: hidden;
}

.Description {
  max-lines: 3;
  max-width: 90%;
  font-size: small;
  color: $descriptionColor;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 80px;
  margin-bottom: 3em;
}