@import "utils";
@import "variables";
@import "animations";
@import "mixins";

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FFFFFF;
}

.Container {
  margin: 0;
}

a {
  text-decoration: none;
}