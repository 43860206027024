@import "../../../styles/base";

.NotFoundPageContainer {
  .ScreenContent {
    background: rgb(2,0,36);
    background: linear-gradient(23deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);
    color: white;
    height: 100vh;
    width: 100vw;
  }

  .NotFoundDescription {
    display: flex;
    place-items: center center;
    justify-content: space-around;
    flex: 1;
    text-align: center;
    height: 80%;

  }
}