@import "styles/animations";
@import "styles/base";
@import "styles/variables";
@import "styles/utils";
@import "styles/mixins";

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  font-size: 16px;
  line-height: 1.56;
  color: black;
  background-color: #edf1f7;
}

a,.Link {
  color: inherit;
  text-decoration: none;
  transition: 1s;
}

* {
  box-sizing: border-box;
}

a:hover {
  color: $primaryColor;
}

a, p, h1, h2, h3, h4, h5, label {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

* , *:after, *:before {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif, 'Noto Sans SC', sans-serif;
  scroll-behavior: smooth;
}

.App {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

* , *:after, *:before {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif, 'Noto Sans SC', sans-serif;
  scroll-behavior: smooth;
}