@import "variables";

.Line {
  background-color: $primaryColor;
  height: 0.3em;
  width: 100%;
}

.title {
  color: $primaryColor;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
